import API from "../plugins/http";
const Users = {
  namespaced: true,
  state: {
    items: [],
    buffets: [],
    subscription: [],
    single: [],
    userbanned: [],
    logscreenshots : [],
    userBannedDetailUserId:{},
    totalRowsBanned: 0,
    totalRowsScreenShot: 0,
    totalRows: 0,
    countSingle: 0,
    isLoading: true,
    isLoadingItunes: true,
    LoadingUnbanned: false,
    isError: false,
    totalRowsBuffets: 0,
    isLoadingBuffet: false,
    isErrorBuffet: false,
    totalRowsSingle: 0,
    isLoadingSingle: false,
    isErrorSingle: false,
    itunes: [],
    User: {},
    UserItem: [],
    isLoadingUserItem: true,
    errorMessage: ''
  },
  getters: {
    users: (state) => state.items,
    buffets: (state) => state.buffets,
    subscription: (state) => state.subscription,
    userBannedDetailUserId: (state) => state.userBannedDetailUserId,
    logscreenshots:(state) => state.logscreenshots,
    single: (state) => state.single,
    isError: (state) => state.isError,
    itunes: (state) => state.itunes,
  },

  actions: {
    async fetchUsers({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(`users`, { params: state });
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
        return response
      } catch (error) {
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        let response = error
        if (response.status == 403) {
          let err = response.data.message
          console.log(err);
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },
    async fetchUsersBanned({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `user-banned`,
          {
            params: state,
          }
        );

        commit("setUserBanned", response.data.data.rows);
        commit("setRowsBanned", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setUserBanned", []);
        commit("setRowsBanned", 0);
        commit("setLoading", false);
        commit("setError", true);
        let response = error
        if (response.status == 403) {
          let err = response.data.message
          console.log(err);
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },
    async fetchBanned({ commit }, state) {
      commit("setLoading", true);
      try {
        const response = await API.get(`/user-banned/check-banned`, { params: state });   
        commit("setUserBannedDetailUserId", response.data.data);
        commit("setLoading", false);
      } catch (error) {
        console.error("Error fetching banned users:", error);
        commit("setError", true);
        commit("setErrorMessage", error.message || "Failed to fetch banned users");
      } finally {
        commit("setLoading", false);
      }
    },

    async fetchLogScreenshots({ commit }, state) {
      commit("setLoading", true);
      try {
        const response = await API.get(`/user-banned/log-screenshots`, { params: state });     
        commit('setLogScreenShoot',response.data.data.rows)
        commit("setRowScreenShot", response.data.data.count);
      } catch (error) {
        console.error("Error fetching banned users:", error);
        commit("setError", true);
        commit("setErrorMessage", error.message || "Failed to fetch banned users");
      } finally {
        commit("setLoading", false);
      }
    },
    async fetchSearchLogScreenshots({ commit }, state) {
      commit("setLoading", true);
      try {
        const response = await API.get(`/user-banned/log-screenshots/search`, { params: state });     
        commit('setLogScreenShoot',response.data.data.rows)
        commit("setRowScreenShot", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        console.error("Error fetching banned users:", error);
        commit("setError", true);
        commit("setErrorMessage", error.message || "Failed to fetch banned users");
      } finally {
        commit("setLoading", false);
      }
    },
    
    async SearchUsersBanned({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `user-banned/search`,
          { params: state }
        );

        commit("setUserBanned", response.data.data.rows);
        commit("setRowsBanned", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setUserBanned", []);
        commit("setRowsBanned", 0);
        commit("setLoading", false);
        commit("setError", true);
        let response = error
        if (response.status == 403) {
          let err = response.data.message
          console.log(err);
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },
    async UnbannedUser({ commit }, state) {
      try {
        console.log(state)
        const response = await API.post(
          `user-banned/unbanned`, `${state}`
        );
        commit("setLoadingUnbanned", false);
        return response.data
      } catch (error) {
        commit("setError", true);
        return error
      }
    },
    async bannedUser({ commit }, state) {
      commit("setLoading", true);
      try {
        commit("setLoading", false);
        const response = await API.post(
          `user-banned/banned`, state
        );
        return response;
      } catch (error) {
        commit("setLoading", false);
        return error;
      }
    },
    async fetchBuffets({ commit }, state) {
      console.log(`call fun fetch buffets`);
      try {
        commit("setLoading", true);
        const response = await API.get(`user-buffets/${state.email}`);

        commit("setBuffets", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setBuffets", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        let response = error
        if (response.status == 403) {
          let err = response.data.message
          console.log(err);
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },
    async fetchSingle({ commit }, state) {
      try {
        commit("setIsloadingUserItem", true);
        const response = await API.get(
          `user-items/${state.email}/?&page=${state.page}&limit=${state.limit}&email=${state.email}&premium=${state.premium}&all=${state.selectAll}`
        );
        commit("setUserItem", response.data.data.rows);
        commit("setCountSingle", response.data.data.count);
        commit("setIsloadingUserItem", false);
      } catch (error) {
        commit("setUserItem", []);
        commit("setIsloadingUserItem", false);
        commit("setError", true);
        let response = error
        if (response.status == 403) {
          let err = response.data.message
          console.log(err);
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },
    async fetchItunes({ commit }, state) {
      try {
        commit("setLoadingItunes", true);
        console.log(state)
        const response = await API.get(`payment-itunes/${state}`);

        commit("setItunes", response.data.data);
        commit("setLoadingItunes", false);

      } catch (error) {
        console.log(error);
        commit("setItunes", []);
        commit("setLoadingItunes", false);
        commit("setError", true);
        let response = error
        if (response.status == 403) {
          let err = response.data.message
          console.log(err);
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    }, async fetchItunesByResponse({ commit }, state) {
      try {
        commit("setLoadingItunes", true);
        const response = await API.get(`payment-itunes/${state}`);
        return response.data.data;

      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async deleteDeviceId({commit}, id) {
      commit("setLoading", true);
      try {
        const response = API.delete(`users/delete-device/${id}`)
        commit("setLoading", false);
        return response
      } catch(err) {
        console.log(err)
      }
    },
    async fetchUserByid({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(`users/${state.id}`);
        commit("setUser", [response.data.data]);
        commit("setLoading", false);
      } catch (error) {
        console.log(error);
        commit("setUser", []);
        commit("setLoading", false);
        commit("setError", true);
        let response = error
        if (response.status == 403) {
          let err = response.data.message
          console.log(err);
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },
    async fetchUserSubscription({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(`user-subscriptions/${state.email}`);
        commit("setSubscription", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setSubscription", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        let response = error
        if (response.status == 403) {
          let err = response.data.message
          console.log(err);
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },
    async resetPassword({commit}, payload){
      commit("setLoading", false);
      return API.post('users/send-reset-password', payload)
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLoadingItunes(state, data) {
      state.isLoadingItunes = data;
    },
    setLoadingUnbanned(state, data) {
      state.LoadingUnbanned = data
    },
    setError(state, data) {
      state.isError = data;
    },
    setBuffets(state, item) {
      state.buffets = item;
    },
    setRowsBuffets(state, data) {
      state.totalRowsBuffets = data;
    },
    setLoadingBuffets(state, data) {
      state.isLoadingBuffet = data;
    },
    setErrorBuffets(state, data) {
      state.isErrorBuffet = data;
    },
    setSingle(state, item) {
      state.single = item;
    },
    setRowsSingle(state, data) {
      state.totalRowsSingle = data;
    },
    setLoadingSingle(state, data) {
      state.isLoadingSingle = data;
    },
    setErrorSingle(state, data) {
      state.isErrorSingle = data;
    },
    setUserBanned(state, data) {
      state.userbanned = data
    },
    setLogScreenShoot(state,data) {
      state.logscreenshots = data
    },
    setRowsBanned(state, data) {
      state.totalRowsBanned = data
    },
    setRowScreenShot(state, data) {
      state.totalRowsScreenShot = data
    },
    setItunes(state, data) {
      state.itunes = data
    },
    setUser(state, data) {
      state.User = data
    },
    setUserBannedDetailUserId(state, data) {
      state.userBannedDetailUserId = data
    },
    setUserItem(state, data) {
      state.UserItem = data
    },
    setSubscription(state, data) {
      state.subscription = data;
   },
    setIsloadingUserItem(state, data) {
      state.isloadingUserItem = data
    },
    setErrorMessage(state, data) {
      state.errorMessage = data
    },
    setCountSingle(state, data) {
      state.countSingle = data
    }

  },
};

export default Users;
