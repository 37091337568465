import API from '../plugins/http';
import Utils from '../plugins/util';

const discounts = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    iosTier:[],
    androidTier:[],
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isError: false,
    refresh: false,
    usage: [],
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    isLodingBtnSearch: false,
  },
  getters: {
  },
  actions: {
    async fetchDiscounts({ commit }, props) {
      try {
        commit('setError', false);
        commit('setLoading', true);
        let URL = `discounts-offer?page=${props.page}&limit=${props.limit}`;
        if (props.to) {
          URL += `&to=${props.to}`
        } else if (props.from) {
          URL += `&from=${props.from}`
        } else if (props.search) {
          URL += `&name=${Utils.encodeUrlWithBase64(props.search)}`
        }
        const response = await API.get(URL);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async fetchDiscountsTiercodeIos({ commit }) { 
   
      try {
        commit('setError', false);
        commit('setLoading', false);
        let URL = `tiercode-ios/search/all-tiercode/source`;
        const response = await API.get(URL);
        commit('setTierIos', response.data.data);
        commit('setLoading', false);
        return response.data.data
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchDiscountsTiercodeAndroid({ commit }) { 
      try {
        commit('setError', false);
        commit('setLoading', true);
        let URL = `tiercode-andriod/search/all-tiercode/source`;
        const response = await API.get(URL);
        commit('setTierAndroid', response.data.data);
        commit('setLoading', false);
        return response.data.data
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchDiscountsTiercodeIosSearch({ commit },props) { 
      commit('setError', false);
      try {
        const response = await API.get(
          `tiercode-ios/search/tiercode/${props.q}`
        );
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchDiscountsTiercodeAndroidSearch({ commit },props) { 
      commit('setError', false);
      try {
        const response = await API.get(
          `tiercode-andriod/search/tiercode/${props.q}`
        );
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async fetchSpecifiedOffers({ commit }, props) {
      try {
        commit('setError', false);
        commit('setLoading', true);
        const response = await API.get(
          `discounts-offer/discounts-specified/${props.id}?page=${props.page}&limit=${props.limit}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async fetchDiscountsByid({ commit }, props) {
      try {
        const response = await API.get(`discounts-offer/${props.id}`);
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchDiscountsDetailByid({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(`discounts-offer/${props}`);
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', 1);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchCampaignById({ commit }, props) {
      commit('setLoading', false);
      try {
        const response = await API.get(`campaign/${props}`);
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchDiscountsPlatformsid({ commit }, props) {
      try {
        commit('setLoading', false);
        const response = await API.get(
          `discounts-offer/discount-platform/${props}`,
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async fetchDiscountsPaymentsid({ commit }, props) {
      try {
        commit('setLoading', false);
        const response = await API.get(
          `discounts-offer/discount-payment-gateway/${props}`,
        );
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchDiscountsOffersid({ commit }, props) {
      try {
        commit('setLoading', false);
        const response = await API.get(
          `discounts-offer/discounts-offers/${props}`,
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async fetchDiscountsVendorid({ commit }, props) {
      try {
        commit('setLoading', false);
        const response = await API.get(
          `discounts-offer/discounts-vendor/${props}`,
        );
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchVendorsId({ commit }, state) {
      commit('setLoading', false);
      try {
        const response = await API.get(`discounts-offer/vendor/${state}`);
        return response;
      } catch (error) {
        return error;
      }
    },
    async fetchDiscountsUsage({ commit }, props) {
      try {
        commit('setLoadingUsage', true);
        const response = await API.get(
          `voucher-usage/?page=${props.page}&limit=${props.limit}`,
        );
        commit('setUsage', response.data.data.rows);
        commit('setRowsUsage', response.data.data.count);
        commit('setLoadingUsage', false);
      } catch (error) {
        commit('setLoadingUsage', false);
        commit('setErrorUsagee', true);
      }
    },
    async fetchDiscountsUsageByid({ commit }, props) {
      try {
        commit('setLoadingUsage', true);
        const response = await API.get(`voucher-usage/${props.q}`);
        console.log(response.data.data.rows);
        commit('setUsage', response.data.data.rows);
        commit('setRowsUsage', response.data.data.count);
        commit('setLoadingUsage', false);
      } catch (error) {
        commit('setLoadingUsage', false);
        commit('setErrorUsagee', true);
      }
    },
    async searchDiscounts({ commit }, props) {
      commit('setLoading', true);
      try {
        const q = Utils.encodeUrlWithBase64(props.search);
        const response = await API.get(`search-discounts/search?search=${q}`);
        commit('setRows', response.data?.data?.count);
        commit('setItems', response.data?.data?.rows);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },
    async getDiscountName({ commit }, props) {
      try {
        const response = await API.get(
          `search-discounts/search`, {params: props},
        );
        commit('setItems', response.data.data.name);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },
    async fetchDiscountById({ commit }, state) {
      try {
        const response = await API.get(`discounts/search-by-id/${state.id}`);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async discountPost({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.post(`discounts-offer`, payload);
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Created');
          commit('setItem', response.data?.data?.rows[0]);
          commit('setLoading', false);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.data.message;
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async discountUpdate({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.put(
          `discounts-offer/${payload.id}`,
          payload,
        );
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Update success');
          commit('setItem', response.data?.data?.rows[0]);
          commit('setLoading', false);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) {
          errMsg = response.statusText;
        }
        if (response.status == 422) {
          errMsg = response.data.message;
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async reactiveDiscount({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put(
          `discounts-offer/activate/${payload.id}`,
          payload,
        );
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Update success');
        }
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.data.message;
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async deactiveDiscount({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.put(
          `discounts-offer/activate/${payload.id}`,
          payload,
        );
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Update success');
        }
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.data.message;
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async searchVendors({ commit }, state) {
      try {
        const q = Utils.encodeUrlWithBase64(state.q);
        const response = await API.get(`search-vendor?name=${q}`);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },
    async searchBulkyOffers({ commit }, state) {
      try {
        let url;
        if (state.platform === 4) {
          url = `discounts-offer/search/offers/${state.q}`;
        } else {
          url = `discounts-offer/search/offers/${state.q}?ispremium=${state.ispremium}&platform=${state.platform}`;
        }    
        const response = await API.get(url);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        console.error('Error fetching bulky offers:', error);
        return error;
      }
    },
    
    async searchBulkyOffersByNumber({ commit }, payload) {
      commit('setLoading', true);
    
      const data = {
        offer_id: payload
      };
      try {
        const response = await API.post(`discounts-offer/search/bulk-offers`,data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        console.error('Error in searchBulkyOffersByNumber:', error);
        commit('setLoading', false);
        return error;
      }
    },
       
  
    async fetchVendorsById({ commit }, state) {
      try {
        const response = await API.get(`vendor/${state.id}`);
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },

    async reIndexItemByOffers({ commit }, props) {
      commit('setLoading', true);
      try {
        await API.post('discounts-offer/discounts-specified/re-index', props);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
      }
    },

    async addOfferDiscSpecified({ commit }, props) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        await API.post(
          `discounts-offer/discounts-specified/${props.id}/offer`,
          {
            offers: props.offers,
          },
        );
        commit('setSuccessMessage', 'offer success added');
        commit('setLoading', false);
      } catch (error) {
        commit('setErrorMessage', 'please try again');
        commit('setLoading', false);
      }
    },

    async searchOffers({ commit }, props) {
      try {
        const q = Utils.encodeUrlWithBase64(props.query);
        const offers = await API.get(
          `discounts-offer/search/offers/${q}`,
          props,
        );
        return offers;
      } catch (error) {
        commit('setErrorMessage', error.message);
      }
    },

    async fetchDiscountOffer({ commit }, props) {
      try {
        const response = await API.get(`/discounts-offer/selected/offers`, {
          params: props,
        });
        commit('setItems', response.data.data);
      } catch (error) {
        commit('setLoading', false);
      }
    },

    async searchDiscountsBydate({ commit }, props) {
      try {
        commit('setErrorMessage', '');
        commit('setLoadingBtn', true);
        commit('setLoading', true);
        commit('setItems', []);
        const response = await API.get(`discounts-offer`, {
          params: props,
        });
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoadingBtn', false);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setItems', []);
        commit('setLoadingOne', false);
        commit('setErrorOne', true);
        commit('setErrorMessage', error.response.data.message);
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setTierIos(state, item) {
      state.iosTier = item;
    },
    setTierAndroid(state,item) {
      state.androidTier = item
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
    
    setUsage(state, item) {
      state.usage = item;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setLoadingBtn(state, data) {
      state.isLodingBtnSearch = data;
    },
    setLoadingOne(state, data) {
      state.isLoadingOne = data;
    },
  },
};

export default discounts;
